import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Helmet } from "react-helmet";
import ErrorAlert from "../../components/ErrorAlert";
import LoadingIndicator from "../../components/LoadingIndicator";
import RequestQuoteButton from "../../components/RequestQuoteButton";
import TableWrapper from "../../components/TableWrapper";
import * as Constants from "../../constants";
import useCapabilitiesContext from "../../hooks/useCapabilitiesContext";
import styles from "./css/CapabilityTable.module.css";

const columns = [
  {
    text: "index",
    dataField: "index",
    hidden: true,
    csvExport: false,
  },
  {
    text: "Aircraft",
    dataField: "aircraft",
    filter: textFilter(),
    sort: true,
  },
  {
    text: "Part Number",
    dataField: "partNumber",
    filter: textFilter(),
    sort: true,
  },
  {
    text: "ATA Number",
    dataField: "ata",
    filter: textFilter(),
    sort: true,
  },
  {
    text: "Nomenclature",
    dataField: "nomenclature",
    filter: textFilter(),
    sort: true,
  },
  {
    text: "EASA",
    dataField: "easa",
    csvType: Boolean,
    sort: true,
    formatter: boolFormatter,
    formatExtraData: {
      true: "fa fa-check",
      false: "",
    },
  },
  {
    text: "CAAC",
    dataField: "caac",
    csvType: Boolean,
    sort: true,
    formatter: boolFormatter,
    formatExtraData: {
      true: "fa fa-check",
      false: "",
    },
  },
  {
    text: "DER Available",
    dataField: "derAvailable",
    csvType: Boolean,
    sort: true,
    formatter: boolFormatter,
    formatExtraData: {
      true: "fa fa-check",
      false: "",
    },
  },
  {
    text: "Request Quote",
    dataField: "requestQuote",
    csvExport: false,
  },
];

function boolFormatter(cell, row, rowIndex, formatExtraData) {
  return <div style={booleanColumnStyle} className={formatExtraData[cell]} />;
}

const booleanColumnStyle = {
  textAlign: "center",
  width: "100%",
};

const ExportButton = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button className="btn btn-primary" onClick={handleClick}>
        <i className="fa fa-download"></i> Export{" "}
      </button>
    </div>
  );
};

const PaginationTotal = (from, to, size) => (
  <span className={styles.paginationTotal}>
    Showing {from} to {to} of {size} entries
  </span>
);

const CapabilitiesTable = ({ capabilities }) => {
  const capabilitiesWithButtons = capabilities.map((capability, index) => ({
    ...capability,
    index: index,
    easa: true,
    caac: capability.caac,
    requestQuote: <RequestQuoteButton partNumber={capability} />,
    derAvailable: capability.derAvailable,
  }));

  return (
    <TableWrapper title="Capabilities">
      <ToolkitProvider
        keyField="index"
        data={capabilitiesWithButtons}
        columns={columns}
        exportCSV={{
          fileName: "CapabilityData.csv",
          onlyExportFiltered: true,
          exportAll: false,
          blobType: "text/csv;charset=ansi",
        }}
        search
      >
        {(props) => (
          <div className={styles.bootstrapTable}>
            <div className={styles.exportButtonContainer}>
              <ExportButton {...props.csvProps} />
            </div>
            <BootstrapTable
              striped
              {...props.baseProps}
              pagination={paginationFactory({
                showTotal: true,
                paginationTotalRenderer: PaginationTotal,
                sizePerPageList: [20, 50, 75, 100],
              })}
              filter={filterFactory()}
              filterPosition="top"
            />
          </div>
        )}
      </ToolkitProvider>
    </TableWrapper>
  );
};

const Capabilities = () => {
  const capabilities = useCapabilitiesContext();

  const getContent = () => {
    switch (capabilities.status) {
      case Constants.LOADED:
        return <CapabilitiesTable capabilities={capabilities.data} />;
      case Constants.ERROR:
        return <ErrorAlert message={capabilities.error} />;
      case Constants.LOADING:
        console.log("Returning loading element");
        return <LoadingIndicator message="Loading Capabilities" />;
      default:
        return <ErrorAlert message="Unknown Error" />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Perform Air: Capabilities</title>
        <meta
          name="description"
          content="Search a list of Perform Air International's Capabilities"
        />
      </Helmet>
      <div className="App-body">{getContent()}</div>
    </>
  );
};

export default Capabilities;
