import { useContext } from "react";
import AppContext from "../context/AppContext";
import * as Constants from "../constants";

export default function useRotablesContext() {
  const { rotables } = useContext(AppContext);
  if (rotables.status === Constants.WAITING) {
    rotables.load();
  }
  return {
    status: rotables.status,
    data: rotables.data,
    error: rotables.error,
  };
}
