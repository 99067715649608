import React from "react";
import { withRouter } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import NotFound from "./components/NotFound";
import AppContext from "./context/AppContext";
import useCapabilities from "./hooks/useCapabilities";
import useRotables from "./hooks/useRotables";
import Capabilities from "./sections/capabilities/Capabilites";
import HomePage from "./sections/homepage/HomePage";
import PressReleases from "./sections/press/PressReleases";
import RFQ from "./sections/rfq/RFQ";
import Rotables from "./sections/rotables/Rotables";
import Team from "./sections/team/Team";
import Social from "./sections/social/Social";

function App() {
  const HeaderWithRouter = withRouter(Header);

  const capabilities = useCapabilities();
  const rotables = useRotables();

  return (
    <AppContext.Provider value={{ capabilities, rotables }}>
      <div className="d-flex flex-column App">
        <Router>
          <HeaderWithRouter />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/capabilities" component={Capabilities} />
            <Route path="/rotables" component={Rotables} />
            <Route path="/press" component={PressReleases} />
            <Route path="/contacts" component={Team} />
            <Route path="/rfq" component={RFQ} />
            <Route path="/social" component={Social} />
            <Route component={NotFound} />
          </Switch>
        </Router>

        <div className="App-section dark">
          <Footer />
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
