import React from "react";
import { Alert } from "react-bootstrap"

const ErrorAlert = ({message}) => {
    return (
        <Alert className="mt-5" variant="danger" show={true}>
            <Alert.Heading>Server Error</Alert.Heading>
            <p>
                {message}
            </p>
        </Alert>
    );
}

export default ErrorAlert;