import React from 'react';
import styles from './css/ImageCard.module.css';

const ImageCard = ({ image, title }) => {
    return (
        <div className={styles.ImageCard}>
            <div className={styles.imageWrapper}>
                <img src={image} alt={title} />
            </div>
            <p className={styles.Title}>{title}</p>
        </div>
    )
};

export default ImageCard;