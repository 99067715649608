import React from 'react'
import TeamMemberCard from './TeamMemberCard';
import styles from './css/Team.module.css';
import TeamMembers from '../../data/TeamMembers.json';
import President from '../../data/President.json';
import EVPs from '../../data/EVPs.json';
import VPs from '../../data/VPs.json';
import Directors from '../../data/Directors.json';
import { Helmet } from "react-helmet";

function TeamSection(props) {
    return <div className={styles.TeamSection}>
        {
            props.members.map(({ img, name, department, title, telephone, email }) => (
                <TeamMemberCard
                    key={email}
                    img={img}
                    name={name}
                    department={department}
                    title={title}
                    telephone={telephone}
                    email={email}
                />
            ))
        }
    </div>
}

function Team() {
    return (
        <>
            <Helmet>
                <title>Perform Air: Contact Us</title>
                <meta name="description" content="View the contact information of Perform Air management" />
            </Helmet>

            <div className="App-section light">
                <div className="App-section-content">
                    <h1 className="Center">Meet Our Management Team</h1>
                    <TeamSection members={President} />
                    <TeamSection members={EVPs} />
                    <TeamSection members={VPs} />
                    <TeamSection members={Directors} />
                    <TeamSection members={TeamMembers} />
                </div>
            </div>
        </>
    )
}

export default Team;
