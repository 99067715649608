import React from 'react';
import ImageCard from './ImageCard'
import styles from './css/SpecialtiesSection.module.css';

import {
    ElectroMechanical, Fuel, Hydraulics, Pneumatics, WaterAndWaste
} from '../../images'
import ContentDivider from '../../../../components/ContentDivider';

const specialties = [
    {
        image: ElectroMechanical,
        title: "Electro-Mechanical"
    },
    {
        image: Fuel,
        title: "Fuel"
    },
    {
        image: Hydraulics,
        title: "Hydraulics"
    },
    {
        image: Pneumatics,
        title: "Pneumatics"
    },
    {
        image: WaterAndWaste,
        title: "Water & Waste"
    }
]

function SpecialtiesSection() {
    return (
        <div className ={styles.SpecialtiesSection}>
            <div className="App-section light">
                <div className="App-section-content">
                    <h1>Specialties</h1>
                    <ContentDivider />
                    <p>Perform Air International specializes in the following component types:</p>            
                    <div className={styles.Categories}>
                        {
                            specialties.map(({ image, title }) => (
                                <ImageCard
                                    key={title}
                                    image={image}
                                    title={title}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecialtiesSection;