import React from "react";
import { Helmet } from "react-helmet";
import styles from "./css/PressReleases.module.css";

function PressRelease(props) {
  return <div className="App-section-content">{props.children}</div>;
}

function PressReleaseTitle(props) {
  return <h2 className="Bold">{props.children}</h2>;
}

function PressReleaseDate(props) {
  return <p className="Colored">{props.children}</p>;
}

function PressReleaseContent(props) {
  return <>{props.children}</>;
}

function PressReleaseSection(props) {
  return <p>{props.children}</p>;
}

function Closing(props) {
  return (
    <div className={styles.Closing}>
      <PressReleaseSection>Cindy McGown</PressReleaseSection>
      <PressReleaseSection>President</PressReleaseSection>
      <PressReleaseSection>Perform Air International Inc.</PressReleaseSection>
    </div>
  );
}

function CoronavirusContingencyPlanPressRelease() {
  return (
    <PressRelease>
      <PressReleaseTitle>
        Perform Air International Inc. Coronavirus Contingency Plan
      </PressReleaseTitle>
      <PressReleaseDate>March 17th 2020</PressReleaseDate>
      <PressReleaseContent>
        <PressReleaseSection>To our Customers;</PressReleaseSection>
        <PressReleaseSection>
          Perform Air International Inc. has taken steps to protect both our
          employees and our customers during this difficult time for the world
          and would like to update you on the actions we have taken to date.
        </PressReleaseSection>
        <PressReleaseSection>
          Our priority is the health and safety of our staff. To ensure a safe
          work environment we have established protocols for daily sanitation of
          all areas within the 2 buildings. All PPE is in place with a 90-day
          supply on hand.
        </PressReleaseSection>
        <PressReleaseSection>
          Our process for work from home scenarios associated with our Business
          Continuity Plan began implementation on 3/12/2020. All high-risk
          employees were sent home Friday with the capability to work from home.
          On Monday 3/16/2020 all other administrative employees were processed
          and were released to work from home. Left in the administrative
          offices is one executive level management team member to ensure all
          required functions at the office continue.
        </PressReleaseSection>
        <PressReleaseSection>
          All Safety Sensitive positions will remain at work, with the
          availability to change to either the 4x10hour shift or the 3x12hour
          shift as required due to school closures.
        </PressReleaseSection>
        <PressReleaseSection>
          To ensure a safe work environment we have established protocols for
          daily sanitation of all areas within the 2 buildings. All PPE is in
          place with a 90-day supply on hand. Our process for work from home
          scenarios associated with our Business Continuity Plan began
          implementation on 3/12/2020. All high-risk employees were sent home
          Friday with the capability to work from home. On Monday 3/16/2020 all
          other administrative employees were processed and were released to
          work from home. Left in the administrative offices is one executive
          level management team member to ensure all required functions at the
          office continue. All Safety Sensitive positions will remain at work,
          with the availability to change to either the 4x10hour shift or the
          3x12hour shift as required due to school closures. Our engineering
          staff is in a separate building and have chosen to remain at work as
          they require access to the components to perform their functions.
        </PressReleaseSection>
        <PressReleaseSection>
          Our warehouse and shipping personnel have remained in their areas and
          are taking new sanitation precautions for any items entering the
          building prior to opening all boxes.
        </PressReleaseSection>
        <PressReleaseSection>
          All staff remaining in the office have been segregated into multiple
          break times and lunch breaks to ensure minimal exposure to each other.
        </PressReleaseSection>
        <PressReleaseSection>
          All staff have been educated on the situation and are diligently
          ensuring themselves and their co-workers remain at home if they are
          sick.
        </PressReleaseSection>
        <PressReleaseSection>
          Regarding our extrinsic activity, effective 3/18/2020 Perform Air
          International Inc. will not allow any extrinsic entity to visit our
          facility for audit, excluding regulatory entities. The only acceptable
          external vendors allowed in the building are freight providers who
          remain outside of the building only so the sanitation process can
          occur. We have not experienced any issues with freight delivery and
          are assured we will continue to receive shipments.
        </PressReleaseSection>
        <PressReleaseSection>
          Thus far we have not had any issues with our piece part vendors,
          though they may be working from home we are receiving quotes and are
          able to get the required product.
        </PressReleaseSection>
        <PressReleaseSection>
          Perform Air International Inc. remains committed to ensuring exemplary
          customer satisfaction regardless of the circumstances we are faced
          with. There are additional contingencies in our business continuity
          plan that may be required depending upon the progression of the impact
          as directed by the various governments.
        </PressReleaseSection>
        <PressReleaseSection>
          In conclusion, Perform Air International Inc. understands the impact
          to our industry specifically and will continue to do all that we can
          to ensure the health and safety of our employees, while continuing to
          provide service our customers.
        </PressReleaseSection>
        <Closing />
      </PressReleaseContent>
    </PressRelease>
  );
}

function NationalAviationHeritageSponsorship() {
  return (
    <PressRelease>
      <div className={styles.ImageContainer}>
        <img
          src={require(`./images/stihl_logo.png`)}
          alt="stihl"
          height="250px"
        />
        <img
          src={require(`./images/NAHI_logo.png`)}
          alt="nahi"
          height="250px"
        />
      </div>
      <PressReleaseTitle>
        PERFORM AIR INTERNATIONAL to be the PRESENTING SPONSOR for the 2021
        National Aviation Heritage Invitational (NAHI)
      </PressReleaseTitle>
      <PressReleaseDate>March 22nd 2021</PressReleaseDate>
      <PressReleaseContent>
        <PressReleaseSection>
          Reno, Nevada -{" "}
          <b>
            The STIHL National Championship Air Races and the National Aviation
            Heritage Invitational (NAHI)
          </b>{" "}
          are proud to announce that <b>PERFORM AIR INTERNATIONAL</b> will
          return as the 2021 <b>PRESENTING SPONOR</b> for this year’s
          Invitational event. As the Presenting Sponsor their company name will
          be prominently displayed on the perpetual{" "}
          <b>
            NEIL A. ARMSTRONG AVIATION HERITAGE TROPHY, which will be awarded to
            the 2021 Grand Champion.
          </b>{" "}
          The prestigious vintage aircraft competition is hosted by the STIHL
          National Championship Air Races at Stead Field outside of Reno, Nevada
          from Sept 15-19, 2021.
        </PressReleaseSection>
        <PressReleaseSection>
          “This vintage aircraft competition, which was founded in 1998, has two
          primary goals; to promote the preservation of our rich aviation
          history by encouraging the restoration of vintage aircraft to
          airworthy condition and to help inspire our nation’s youth to pursue
          careers within the aviation and aerospace industry”, said Ken Perich,
          Chairman and Executive Director of NAHI.
        </PressReleaseSection>
        <PressReleaseSection>
          To compete, aircraft must be restored to airworthy condition and be
          manufactured at least 45 years ago. For the 2021 competition, all
          aircraft must be manufactured in 1976 or in prior years.
        </PressReleaseSection>
        <PressReleaseSection>
          Cindy McGown, President and CEO of Perform Air International stated,
          “Perform Air is an aviation-based company and we believe in giving
          back to our aviation community. Supporting NAHI is a great way to not
          only encourage the preservation of our aviation history but to provide
          the opportunity for thousands of students to see vintage aircraft up
          close and to meet the pilots and restorers of these timeless aviation
          treasures.”
        </PressReleaseSection>
        <PressReleaseSection>
          The Reno Air Racing Association and the National Aviation Heritage
          Invitational are committed to keeping our rich aviation history alive.
          Since 1999 a panel of highly skilled judge’s review each participating
          aircraft against standards in line with those of the Smithsonian
          Institution’s National Air and Space Museum. This includes;
          authenticity, quality of workmanship, attention to detail and
          technical merit. Aircraft will compete in one of FIVE judging
          categories: Antique, Classic, Contemporary, Military and Large
          Aircraft.
        </PressReleaseSection>
        <PressReleaseSection>
          The overall winning aircraft is awarded the Grand Champion{" "}
          <b>Neil A. Armstrong Aviation Heritage Trophy</b>. The trophy resides
          at the Smithsonian Institution’s National Air and Space Museum’s
          Steven F. Udvar-Hazy Center at Washington Dulles Airport in Virginia.
          The winning aircraft owner’s name and the name of the restoration
          facility is engraved on a plaque placed on the perpetual trophy which
          is sponsored by Perform Air International.
        </PressReleaseSection>
        <PressReleaseSection>
          In addition to the Grand Champion Armstrong Trophy, and the trophies
          for the winners of each of the five judging categories, there is the
          People’s Choice trophy, sponsored by{" "}
          <b>Air {"&"} Space Smithsonian magazine</b>, which is awarded to the
          owner of the aircraft receiving the largest number of votes cast by
          the event attendees.
        </PressReleaseSection>
        <PressReleaseSection>
          For details on eligibility, judging criteria and to complete the entry
          application for the <b>NATIONAL AVIATION HERITAGE INVITATIONAL</b>{" "}
          please visit our website:{" "}
          <a href="http://www.heritagetrophy.com">www.heritagetrophy.com</a>{" "}
          Applications for entry in the 20th annual Invitational must be
          submitted online no later than August 15th, 2021. Each aircraft may
          only be entered in one category, which must be declared on the
          application form.
        </PressReleaseSection>
        <PressReleaseSection>
          <b>
            For further information please visit{" "}
            <a href="http://www.heritagetrophy.com">www.heritagetrophy.com</a>{" "}
            or contact:
          </b>
        </PressReleaseSection>

        <div
          style={{
            fontSize: "20px",
            marginBottom: "1rem",
            fontFamily: "Roboto, sans-serif",
          }}
        >
          <div>June Powers</div>
          <div>Director – Participants</div>
          <div>National Aviation Heritage Invitational</div>
          <div>Tel: 503-851-4337</div>
          Email:
          <a href={"mailto:cjpowers7005@yahoo.com"} target="_top">
            cjpowers7005@yahoo.com
          </a>
        </div>

        <div
          style={{
            fontSize: "20px",
            marginBottom: "1rem",
            fontFamily: "Roboto, sans-serif",
          }}
        >
          <div>Colin Powers</div>
          <div>Chief Judge</div>
          <div>National Aviation Heritage Invitational</div>
          <div>Tel: 503-851-4338</div>
          Email:
          <a href={"mailto:cjpowers7005@yahoo.com"} target="_top">
            cjpowers7005@yahoo.com
          </a>
        </div>

        <div
          style={{
            fontSize: "20px",
            marginBottom: "1rem",
            fontFamily: "Roboto, sans-serif",
          }}
        >
          <div>Connie May</div>
          <div>Director - Communications</div>
          <div>National Aviation Heritage Invitational</div>
          <div>Tel: 775-530-2297</div>
          Email:
          <a href={"mailto:cpardew@att.net"} target="_top">
            cpardew@att.net
          </a>
        </div>
      </PressReleaseContent>
    </PressRelease>
  );
}

function PressReleases() {
  return (
    <>
      <Helmet>
        <title>Perform Air: Press</title>
        <meta name="description" content="Perform Air Press Releases" />
      </Helmet>

      <div className="App-body">
        <div className="App-section light">
          <NationalAviationHeritageSponsorship />
          <CoronavirusContingencyPlanPressRelease />
        </div>
      </div>
    </>
  );
}

export default PressReleases;
