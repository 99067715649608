import React from "react";
import { Helmet } from "react-helmet";
import styles from "./css/Social.module.css";
import SocialMediaCard from "./SocialMediaCard";

const socialMediaPlatforms = [
  {
    name: "Instagram",
    description: "Follow us",
    url: "https://www.instagram.com/performairinternational/",
  },
  {
    name: "Facebook",
    description: "Like our page",
    url: "https://www.facebook.com/PerformAirInternational",
  },
  {
    name: "Twitter",
    description: "Follow us",
    url: "https://twitter.com/performairint",
  },
  {
    name: "LinkedIn",
    description: "Connect with us",
    url: "https://www.linkedin.com/company/perform-air-international-inc.",
  },
];

const Social = () => {
  return (
    <>
      <Helmet>
        <title>Perform Air: Social</title>
        <meta
          name="description"
          content="View the Perform Air Social Media Profiles"
        />
      </Helmet>

      <div className={styles.socials}>
        <div className={styles.socialsContent}>
          <h1 className="Center">Follow Us</h1>
          <div className={styles.cardContainer}>
            {socialMediaPlatforms.map(({ name, description, url }) => (
              <SocialMediaCard
                key={name}
                name={name}
                description={description}
                url={url}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Social;
