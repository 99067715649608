import CertificateCard from "./CertificateCard";
import React from 'react'
import styles from './css/CertificateCard.module.css';
import ContentDivider from "../../../../components/ContentDivider";

const certifications = [
    {
        title: "FAA Repair Station",
        url: 'certificates/FAA_Repair_Station.pdf'
    },
    {
        title: "EASA Repair Station",
        url: 'certificates/EASA.pdf'
    },
    {
        title: "CAAC Repair Station",
        url: 'certificates/CAAC.pdf'
    },
    {
        title: "FAA Approved Drug Program",
        url: 'certificates/FAA_Approved_Drug_Program.pdf'
    },
    {
        title: "ISO 9001",
        url: 'certificates/ISO_9001_Registration.pdf'
    },
    {
        title: "ISO 14001",
        url: 'certificates/ISO_14001_Registration.pdf'
    },
    {
        title: "Woman Owned Business",
        url: 'certificates/Woman_Owned_Business_Certificate.pdf'
    },
    {
        title: "ECO Warranty",
        url: 'certificates/Eco_Warranty.pdf'
    }
];

function CertificateSection() {
    return (
        <div className={styles.Certificates}>
            <div className="App-section">
                <div className="App-section-content">
                    <h1>Our Certifications</h1>
                    <ContentDivider />
                    <div className={styles.CardContainer}>
                        {
                            certifications.map(({ title, url }) => (
                                <CertificateCard
                                    key={title}
                                    title={title}
                                    url={url}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CertificateSection;