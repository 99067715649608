import { useEffect, useState } from "react";
import * as Constants from "../constants";
import api from "../api";

export default function useRotables() {
  const [rotables, setRotables] = useState({
    status: Constants.WAITING,
    data: [],
    error: null,
    load() {
      if (this.status === Constants.WAITING)
        setRotables((prevState) => ({
          ...prevState,
          status: Constants.LOADING,
        }));
    },
  });

  useEffect(() => {
    if (rotables.status === Constants.LOADING)
      api.fetchRotables(
        (rotablesData) =>
          setRotables((prevState) => ({
            ...prevState,
            status: Constants.LOADED,
            data: rotablesData,
          })),
        (_) =>
          setRotables((prevState) => ({
            ...prevState,
            status: Constants.ERROR,
            error: "Error fetching rotables",
          }))
      );
  }, [rotables.status]);

  return rotables;
}
