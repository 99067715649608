import React from 'react';
import logo from '../../../../images/Perform_Air_Logo_white.svg';
import styles from './css/WelcomeSection.module.css';

function Welcome(props) {
    return <div className={styles.Welcome}>
        {props.children}
    </div>
}

function PAILogo() {
    return <div className={styles.welcomeContent}>
        <img src={logo} className={styles.logo} alt="Perform Air International Logo" />
    </div>
}

const WelcomeSection = (props) => {
    return (
        <Welcome>
            <PAILogo />
        </Welcome>
    )
};

export default WelcomeSection;