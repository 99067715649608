import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../images/PAI-diamond.svg";
import styles from "./Header.module.css";
import useDocumentScrollThrottled from "./UseDocumentScrollThrottled";

const Header = (props) => {
  const [shouldResizeHeader, setShouldResizeHeader] = useState(false);
  const resizeStyle = shouldResizeHeader
    ? styles.Header + " " + styles.resize
    : styles.Header;

  useDocumentScrollThrottled((callbackData) => {
    const { currentScrollTop } = callbackData;
    setShouldResizeHeader(currentScrollTop > 2);
  });

  return (
    <Navbar className={`${resizeStyle}`} fixed="top" expand="lg">
      <Navbar.Brand href="/">
        <img
          src={logo}
          className={styles.HeaderLogo}
          alt="Perform Air International Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavLink activeClassName={styles.Active} exact to="/">
            Home
          </NavLink>
          <NavLink activeClassName={styles.Active} exact to="/capabilities">
            Capabilities
          </NavLink>
          <NavLink activeClassName={styles.Active} exact to="/rotables">
            Rotables
          </NavLink>
          <NavLink activeClassName={styles.Active} exact to="/rfq">
            RFQ
          </NavLink>
          <NavLink activeClassName={styles.Active} exact to="/contacts">
            Contact Us
          </NavLink>
          <NavLink activeClassName={styles.Active} exact to="/social">
            Social
          </NavLink>
        </Nav>

        <NavLink to="press">Press Releases</NavLink>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
