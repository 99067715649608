import React from 'react';
import styles from './css/Footer.module.css'
import logo from '../images/Perform_Air_Logo_white.svg';
import { SocialIcon } from 'react-social-icons';

function FooterContainer(props) {
    return <div className={styles.Footer}>
        {props.children}
    </div>
}

function FooterRow(props) {
    return <div className="row">
        {props.children}
    </div>
}

function PAILogo(props) {
    return <div className="col-md-9 col-sm-6 col-xs-12">
        <img src={logo} className={styles.logo} alt="Perform Air International logo" />
    </div>
}

function Contact(props) {
    return <div className="col-md-3 col-sm-6 col-xs-12">
        <div className={styles.contact}>
            <ContactAddress />
            <ContactPhone />
        </div>
    </div>
}

function ContactAddress(props) {
    return <div className={styles.contactItem}>
        <i className="fa fa-map-marker mr-3"></i>
        <p className="mb-0">
            <span>463 S. Hamilton Court | Gilbert, Arizona 85233</span>
        </p>
    </div>
}

function ContactPhone(props) {
    return <div className={styles.contactItem}>
        <i className="fa fa-phone mr-2"></i>
        <p className="mb-0">
            Office: 480.610.3500
        </p>
    </div>
}

function Copyright(props) {
    return <div className="col-md-8 col-sm-6 col-xs-12">
        <p className={styles.copyrightText}>
            © 2012-{(new Date().getFullYear())} Perform Air International, Inc. | All Rights Reserved
        </p>
    </div>
}

function Social(props) {
    return <div className="col-md-4 col-sm-6 col-xs-12">
        <div className={styles.socialIcons}>
            {props.children}
        </div>
    </div>
}

const Footer = (props) => {
    return (
        <FooterContainer>
            <FooterRow>
                <PAILogo />
                <Contact />
            </FooterRow>
            <hr></hr>
            <FooterRow>
                <Copyright />
                <Social>
                    <SocialIcon className="mr-2" url="https://www.facebook.com/PerformAirInternational" bgColor="#2255ce" style={{ height: 30, width: 30 }} />
                    <SocialIcon url="https://www.linkedin.com/company/perform-air-international-inc." bgColor="#2255ce" style={{ height: 30, width: 30 }} />
                </Social>
            </FooterRow>
        </FooterContainer>
    )
};

export default Footer;