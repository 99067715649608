import React from 'react';
import styles from './css/MissionSection.module.css';
import ContentDivider from '../../../../components/ContentDivider.js';

function MissionTitle(props) {
    return <h1>
        {props.children}
    </h1>
}

function MissionContent(props) {
    return <p>
        {props.children}
    </p>
}

function ColoredContent(props) {
    return <p className={styles.ColoredContent}>
        {props.children}
    </p>
}

const GradientCircle = ({link, name}) => {
    return <a className={styles.GradientCircle} href={link}>
        <span>{name}</span>
    </a>
}

const repairStations = [
    {
        name: "FAA",
        link: 'https://www.faa.gov/'
    },
    {
        name: "EASA",
        link: 'https://www.easa.europa.eu/'
    },
    {
        name: "CAAC",
        link: 'http://www.caac.gov.cn/index.html'
    }
]

function MissionSection() {
    return (
        <div className={styles.Mission}>
            <div className="App-section light">
                <div className="App-section-content">
                    <MissionTitle>Our Mission</MissionTitle>
                    <ContentDivider />
                    <MissionContent>It is the intent of Perform Air International Inc. to lead
                    the aviation industry in quality standards, customer satisfaction
                    and technical innovation in the component overhaul and repair
                    services we provide. We strive to maintain a reputation of excellence
                            and achieve goals through the dedication of our professional staff.</MissionContent>

                    <MissionContent>Perform Air International Inc. provides service to both
                    the Commercial and Military Aviation Industries.</MissionContent>
                    <ColoredContent>We are an authorized repair station under:</ColoredContent>
                    <div className={styles.RepairStations}>

                        {
                            repairStations.map(({ name, link }) => (
                                <GradientCircle
                                    key={name}
                                    name={name}
                                    link={link} />
                            ))
                        }
                    </div>
                    <br></br>

                </div>
            </div>
        </div>
    )
}

export default MissionSection;