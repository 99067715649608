import React from 'react'

const TableWrapper = ({ title, children }) => (
    <div className="App-body">
        <div className="App-section light">
            <div className="App-section-content">
                <h1 className="Center">{ title }</h1>
                { children }
            </div>
        </div>
    </div>
);

export default TableWrapper;