import React from 'react'

const NotFound = () => {

    const style = {
        color: "red",
        marginTop: "3em"
    };

    return (
        <div className="App-body">
            <h1 style={style}>Page not found</h1>
        </div>
    );
};

export default NotFound;