import React from 'react';
import '../../App.css';

import CertificateSection from './sections/certificate/CertificateSection';
import SpecialtiesSection from './sections/specialties/SpecialtiesSection';
import AdvantageSection from './sections/advantage/AdvantageSection';
import MissionSection from './sections/mission/MissionSection';
import WelcomeSection from './sections/welcome/WelcomeSection';

class HomePage extends React.Component {
  render() {
    return (
      <div>
        <WelcomeSection />
        <MissionSection />
        <AdvantageSection />
        <CertificateSection />
        <SpecialtiesSection />
      </div>
    );
  }
}

export default HomePage;