import React from 'react';
import styles from './css/CertificateCard.module.css';

const CertificateCard = ({title, url}) => {
    const openURL = () => {
        if(url)
            window.open(url);
    }

    return (
        <div className={styles.CertificateCard} onClick={openURL}>
            <h2>{title}</h2>
        </div>
    )
};

export default CertificateCard;