import { useEffect, useState } from "react";
import api from "../api";
import * as Constants from "../constants";

export default function useCapabilities() {
  const [capabilities, setCapabilities] = useState({
    status: Constants.WAITING,
    data: [],
    error: null,
    load() {
      if (this.status === Constants.WAITING)
        setCapabilities((prevState) => ({
          ...prevState,
          status: Constants.LOADING,
        }));
    },
  });

  useEffect(() => {
      console.log("useCapabilities -> useEffect -> status: " + capabilities.status);
    if (capabilities.status === Constants.LOADING) {
      api.fetchCapabilities(
        (capabilityData) =>
          setCapabilities((prevState) => ({
            ...prevState,
            status: Constants.LOADED,
            data: capabilityData,
          })),
        (_) =>
          setCapabilities((prevState) => ({
            ...prevState,
            status: Constants.ERROR,
            error: "Error fetching capabilities",
          }))
      );
    }
  }, [capabilities.status]);

  return capabilities;
}
