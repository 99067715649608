import React from 'react';
import styles from './css/Team.module.css';

const Name = ({ name }) => <div className={styles.Name}>{name}</div>
const Title = ({ title }) => <div className={styles.Title}>{title}</div>
const Department = ({ department }) => <div className={styles.Department}>{department}</div>

const Contact = ({ telephone, email }) => {
    return <div className={styles.Contact}>
        <p>{telephone}</p>
        <a href={"mailto:" + email} target="_top">{email}</a>
    </div>
}

const TeamMemberCard = ({ img, name, department, title, telephone, email }) => {
    return (
        <div className={styles.TeamMemberCard}>
            <div className={styles.Cropped}>
                <img src={require(`${ img }`)} alt= {name} />
            </div>
            <div className={styles.container}>
                <Name name={name} />
                <Title title={title} />
                <Department department={department} />
                <Contact telephone={telephone} email={email} />
            </div>
        </div>
    )
};

export default TeamMemberCard;