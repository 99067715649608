import React from 'react';
import RequestQuoteForm from './RequestQuoteForm';
import { Helmet } from "react-helmet";

function RFQ() {
    return (
        <>
            <Helmet>
                <title>Perform Air: Send RFQ</title>
                <meta name="description" content="Send a Request For Quote to Perform Air" />
            </Helmet>

            <div className="App-body">
                <div className="App-section light">
                    <div className="App-section-content">
                        <h1 className="Center">Request For Quote</h1>
                        <div style={{ maxWidth: "700px", margin: "auto" }}>
                            <RequestQuoteForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RFQ;