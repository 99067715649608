import React from 'react';
import styles from './LoadingIndicator.module.css';

const LoadingIndicator = ({message}) => (
    <div className={styles.LoadingIndicator}>
        <p>{message || "Loading "}</p>
        <div className={styles["lds-ellipsis"]}>
            <div></div><div></div><div></div><div></div>
        </div>
    </div>
);

export default LoadingIndicator;
