import React from "react";
import { SocialIcon } from "react-social-icons";
import styles from "./css/Social.module.css";

function SocialMediaCard({ name, description, url }) {
  const openURL = () => {
    if (url) window.open(url);
  };

  return (
    <div className={styles.SocialMediaCard} onClick={openURL}>
      <div className={styles.socialMediaCardContent}>
        <SocialIcon
          url={url}
          bgColor="#00c7f7"
          style={{ height: 45, width: 45 }}
        />
        <div className={styles.socialCardDetails}>
          <div className={styles.name}>{name}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    </div>
  );
}

export default SocialMediaCard;
