import { useContext } from "react";
import * as Constants from "../constants";
import AppContext from "../context/AppContext";

export default function useCapabilitiesContext() {
  const { capabilities } = useContext(AppContext);
  if(capabilities.status === Constants.WAITING) {
    capabilities.load();
  }
  return {
      status: capabilities.status,
      data: capabilities.data,
      error: capabilities.error
  }
}
