import React from 'react';
import styles from './css/AdvantageSection.module.css';

import {
  DiamondExcellence
} from '../../images'
import ContentDivider from '../../../../components/ContentDivider';

function AdvantageDiamond() {
  return <div className={styles.DiamondContainer}>
    <img className={styles.Diamond} src={DiamondExcellence} alt="Perform Air International Diamond Excellence: Quality, Turn Time, Service, and Price"></img>
  </div>
}

function AdvantageTitle(props) {
  return <h1 className="Center">
    {props.children}
  </h1>
}

function AdvantageContent(props) {
  return <p className="Center">
    {props.children}
  </p>
}

function AdvantageSection() {
  return (
    <div className={styles.Advantage}>
    <div className="App-section">
      <div className="App-section-content">
        <AdvantageTitle>Perform Air Advantage</AdvantageTitle>
        <ContentDivider />
        <AdvantageContent>Perform Air International Operates with four specific objectives we call the "Diamond Excellence Program".</AdvantageContent> 
        <AdvantageDiamond />
      </div>
      </div>
    </div>
  )
}

export default AdvantageSection;