import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function requestQuoteURL(partNumber) {
    return `/rfq?pn=${partNumber.partNumber}`;
}

const RequestQuoteButton = ({ partNumber }) => (
    <Link to={() => requestQuoteURL(partNumber)}>
        <Button>Request Quote</Button>
    </Link>
);

export default RequestQuoteButton;