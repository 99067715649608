import React from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, useField, FieldArray, ErrorMessage as FormikErrorMessage } from 'formik';
import styles from './css/RFQ.module.css';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import api from '../../api'

const usePartNumberParameter = () => new URLSearchParams(useLocation().search).get("pn") || "";

const WORK_TYPES = ["Inspect", "Repair", "Overhaul", "Test"];

const ErrorMessage = ({ targetName }) => (
    <FormikErrorMessage
        name={targetName}
        render={error => (
            <div className={styles.ErrorMessage}>
                {error}
            </div>
        )}
    />
)

const FieldLabel = ({ children, targetName }) => (
    <div className={styles.FieldLabel}>
        <label htmlFor={targetName}>{children}</label>
        <ErrorMessage targetName={targetName} />
    </div>
);

const TextField = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
        <>
            <FieldLabel
                targetName={props.name}>
                {label}
            </FieldLabel>
            <input {...field} {...props} />
        </>
    );
};

const TextArea = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
        <>
            <FieldLabel
                targetName={props.name}>
                {label}
            </FieldLabel>
            <textarea {...field} {...props} />
        </>
    );
};


const WorkTypes = ({ value }) => {

    const isChecked = workType => value.includes(workType);

    return (
        <>
            <FieldArray name="workTypes"
                render={arrayHelpers => (
                    <div className={styles.Checkboxes}>
                        {
                            WORK_TYPES.map((workType, idx) => (
                                <label key={idx}>{workType}
                                    <input
                                        type="checkbox"
                                        value={workType}
                                        checked={isChecked(workType)}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                arrayHelpers.push(workType);
                                            }
                                            else {
                                                arrayHelpers.remove(value.indexOf(workType));
                                            }
                                        }}
                                    />
                                </label>
                            ))
                        }
                    </div>
                )}
            />
        </>)
}

function RequestQuoteForm() {

    const initialValues = {
        fullName: "",
        email: "",
        company: "",
        partNumbers: usePartNumberParameter(),
        workTypes: [],
        notes: ""
    };


    const validationSchema = Yup.object({
        fullName: Yup.string()
            .max(50, 'Must be 50 characters or less')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        company: Yup.string()
            .max(50, 'Must be 50 characters or less')
            .required('Required'),
        partNumbers: Yup.string()
            .max(150, 'Must be 150 characters or less')
            .required('Required'),
        workTypes: Yup.array()
            .min(1, 'Must select at least one type')
            .required('Required')
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                console.log(`Submitting quote request: ${JSON.stringify(values)}`);
                api.sendQuote(values, () => {
                    alert("Success");
                    setSubmitting(false);
                    resetForm();
                }, error => {
                    alert("Server error, unable to send request");
                    setSubmitting(false);
                    console.error(error);
                });

            }}
        >{({ values, isSubmitting }) => (
            <Form className={styles.RFQForm}>
                <TextField
                    label="Full Name"
                    name="fullName"
                    type="text" />

                <TextField
                    label="Email"
                    name="email"
                    type="text" />

                <TextField
                    label="Company"
                    name="company"
                    type="text" />

                <TextField
                    label="Part Number(s)"
                    name="partNumbers"
                    type="text" />

                <ErrorMessage targetName="workTypes" />

                <WorkTypes value={values.workTypes} />

                <TextArea
                    label="Notes"
                    name="notes"
                    type="textarea" />

                <Button
                    disabled={isSubmitting}
                    className={styles.Button}
                    type="submit">
                    {isSubmitting ? "Sending Request..." : "Submit Request"}
                </Button>
            </Form>
        )}
        </Formik>

    )
}

export default RequestQuoteForm;