import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBDataTable } from "mdbreact";
import React from "react";
import { Helmet } from "react-helmet";
import ErrorAlert from "../../components/ErrorAlert";
import LoadingIndicator from "../../components/LoadingIndicator";
import RequestQuoteButton from "../../components/RequestQuoteButton";
import TableWrapper from "../../components/TableWrapper.js";
import * as Constants from "../../constants";
import useRotables from "../../hooks/useRotablesContext";

const RotablesTable = ({ rotablesData }) => {
  const rotablesWithButtons = rotablesData.map((rotable) => ({
    ...rotable,
    requestQuote: <RequestQuoteButton partNumber={rotable} />,
  }));

  const data = {
    columns: [
      {
        label: "PartNumber",
        field: "partNumber",
        sort: "asc",
        width: 270,
      },
      {
        label: "Nomenclature",
        field: "nomenclature",
        sort: "asc",
        width: 200,
      },
      {
        label: "Request Quote",
        field: "requestQuote",
        width: 100,
      },
    ],
    rows: rotablesWithButtons,
  };

  return (
    <TableWrapper title="Rotables Inventory">
      <MDBDataTable
        className="Table"
        striped
        bordered
        hover
        entries={20}
        data={data}
        noBottomColumns
      />
    </TableWrapper>
  );
};

const Rotables = () => {
  const rotables = useRotables();

  const getContent = () => {
    switch (rotables.status) {
      case Constants.LOADED:
        return <RotablesTable rotablesData={rotables.data} />;
      case Constants.ERROR:
        return <ErrorAlert message={rotables.error} />;
      case Constants.LOADING:
        return <LoadingIndicator message="Loading Rotables" />;
      default:
        return <ErrorAlert message="Unknown Error" />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Perform Air: Rotable Inventory</title>
        <meta
          name="description"
          content="Search Perform Air Rotable Inventory"
        />
      </Helmet>
      <div className="App-body">{getContent()}</div>
    </>
  );
};

export default Rotables;
