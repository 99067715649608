import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL || 'api';

export default {

    fetchRotables(onSuccess, onError) {
        const rotablesURL = baseURL + '/rotables';
        console.log("Fetching rotables from " + rotablesURL);
        axios.get(rotablesURL, {
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(response => response.data)
            .then(onSuccess, onError);
    },

    fetchCapabilities(onSuccess, onError) {
        const capabilitiesURL = baseURL + '/capabilities';
        axios.get(capabilitiesURL, {
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(response => response.data)
            .then(onSuccess, onError);
    },

    sendQuote(quoteRequest, onSuccess, onFailure) {
        let url = `${baseURL}/quote`;
        axios.post(url, quoteRequest)
            .then(onSuccess, onFailure)
    }
}